import * as THREE from 'three'
import { Vector3 } from 'three';
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';
export function createClock(scene)
{
    const d = new Date();
    const r = -2 * Math.PI / 60

    let cylinder = null;
    const loader = new GLTFLoader();
    loader.load('./clock.gltf', function (gltf) {
    gltf.scene.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            console.log(child.name + child.isObject3D)
        }
    })
    
    cylinder = gltf.scene.getObjectByName("Cylinder001");
    cylinder.add(gltf.scene.getObjectByName("Cylinder001_1"))
    cylinder.scale.set(0.12, 0.12, 0.12)
    cylinder.rotateX(Math.PI/2)

    const hour = createHand(0x000000, 0.5);
    cylinder.add(hour);
    hour.rotateY(r * 5 * d.getHours());

    const minute = createHand(0x000000, 0.75);
    cylinder.add(minute);
    minute.rotateY(r * d.getMinutes());

    const second = createHand(0xFF0000, 0.625);
    cylinder.add(second);
    let sec = d.getSeconds();
    let min = d.getMinutes();
    second.rotateY(r * sec);

    var t = setInterval((event) => { 
        second.rotateY(r);
        sec++;
        sec = sec % 60;
        if(sec == 0)
        {
            min++
            min = min % 60;
            minute.rotateY(r);
            if(min == 0)
                hour.rotateY(r * 5);
        }
    },1000);
    scene.add(cylinder);
    cylinder.position.set(-0.75, 0.7, -0.98)
}, undefined, function (error) {

    console.error(error);

})
}
export function createHand(color, length)
{
    const geometry1 = new THREE.BoxGeometry( 0.04, 0.008, length );
    const material1 = new THREE.MeshBasicMaterial( {color: color} );
    const cube = new THREE.Mesh( geometry1, material1 );
    cube.position.y = 0.12
    cube.position.z = -0.5 * length
    const group = new THREE.Group();
    group.add(cube);
    return group;
}