import * as THREE from 'three';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
export function generateFrame(width, height)
{
    const element = document.createElement('div')
    element.id = "framelement"
    element.style.width = width + 'px'
    element.style.height = height + 'px'
    element.style.backgroundColor = 'transparent'
    element.className = "vm-tv-screen-effect"
    
    const iframe = document.createElement('iframe')
    iframe.src = 'https://desktop.brianbaldner.com/'
    iframe.style.width = '100%'
    iframe.style.height = '100%'
    iframe.style.border = 'none'
    iframe.style.backgroundColor = 'transparent'
    element.append(iframe)
    
    const css3dObject = new CSS3DObject(element)
    const ratio = height / width
    css3dObject.scale.set(1 / width, (1 / height) * ratio, 1)
    const imgGeometry = new THREE.PlaneBufferGeometry(1, ratio)
    const imgMaterial = new THREE.MeshBasicMaterial({
        opacity: 0,
        color: new THREE.Color(0xffffff),
        blending: THREE.NoBlending,
        side: THREE.DoubleSide,
        transparent: true,
    })
    
    const webMesh = new THREE.Mesh(imgGeometry, imgMaterial)
    webMesh.add(css3dObject)
    webMesh.scale.set(0.31, 0.281, 0.25)
    webMesh.position.set(0.001, 0.402, -0.9)
    return { webMesh, element };
}