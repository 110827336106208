import * as THREE from 'three'
export function createRenderer(canvas)
{
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        physicallyCorrectLights: false,
        alpha: false,
        powerPreference: "high-performance"
    
    })
    renderer.shadowMap.enabled = true;
    renderer.receiveShadow = true;
    renderer.shadowMap.type = THREE.PCFShadowMap
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setClearColor(0xECF8FF);
    renderer.gammaFactor = 2.2;
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
    renderer.domElement.style.position = 'absolute';
    renderer.domElement.style.zIndex = 1;
    renderer.domElement.style.top = 0;
    renderer.domElement.style.zIndex = 0;
    return renderer;
}