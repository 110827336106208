import * as THREE from 'three'
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';

export function importRoom(scene, controls)
{
    document.getElementById("enterButton").addEventListener("click", () => {
        const r = document.getElementById("load");
        r.style.opacity = 0;
        setTimeout(function(){
            r.style.display = "none";
        }, 1000);
        controls.connect();
        controls.lock();
    });
    const loader = new GLTFLoader();
    loader.load('./room.gltf', function (gltf) {
    gltf.scene.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            //child.matrixAutoUpdate = false;
        }
    })
    scene.add(gltf.scene)
    document.getElementById("enterButton").style.opacity = 1;
    r.addEventListener('transitionend', () => r.remove());
}, undefined, function (error) {

    console.error(error);

})
}